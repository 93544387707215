import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{textAlign: "justify"}}>
            Hi Everyone, I am <span className="purple">Harsh Kohli </span>
            from <span className="purple"> UP, India.</span>
            <br/>
            I am currently staff at BLC, Bot TESTING, Quassars.
            <br/>
            Still in school :/
            <br/>
            Boards this year???
            <br/>
            <br/>
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Valorant, Hypixel, Fortnite, GTA 5(online), etc
            </li>
            <li className="about-activity">
              <ImPointRight /> Listening to Travis Scott
            </li>
            <li className="about-activity">
              <ImPointRight /> Maybe still watching Breaking Bad
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Drink Lassi!"{" "}
          </p>
          <footer className="blockquote-footer">Harsh</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
